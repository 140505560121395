import React, { Component } from 'reactn'
import Datetime from 'react-datetime'
import { AiOutlineFileExcel } from 'react-icons/ai'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { MobileTimePicker } from '@mui/x-date-pickers'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import Modal from 'react-modal'
import { Loader, Sidebar, WithRouter } from '../../Components'
import { Api, clearState, persistState } from '../../Services'
import './css/Admin.css'
import './css/Table.css'
import Pagamento from './Pagamento'

Modal.defaultStyles.overlay.backgroundColor = '#B1AFFFaa'

const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
const MEAL_PRICE = 3.8

class Admin extends Component {
  constructor (props) {
    super(props)
    this.state = {
      companyName: '',
      strutture: [],
      hours: [],
      year: 2024,
      month: moment(new Date()).month(),
      openCalendar: false,
      loading: false,
      dayHeaders: [],

      modalStrutture: false,
      struttura: '',
      showModalPagamento: false,
      pagamento: null,
      priceMeals: 0
    }

    this.datetimeRef = React.createRef()
  }

  async componentDidMount () {
    const { user, company } = this.global

    let companyId = user.company
    if (company) {
      companyId = company
    }

    const res = await Api.get(`/companies/${companyId}`)
    if (res.ok) {
      this.setState({ companyName: res.data.name })
    }

    const { year, month } = this.state

    const strutture = await this.fetchStrutture()
    if (strutture && strutture.length > 0) {
      const baby = strutture[0]._id
      this.setState({ itemSelected: baby, baby })
      await this.fetchHoursMonthly(month, year, baby)
    }
  }

  async fetchHoursMonthly (month, year, baby, disableCache) {
    this.setState({ loading: true })

    const daysInMonth = new Date(year, month + 1, 0).getDate()

    // Generate the headers with day of the week and date
    const dayHeaders = Array.from({ length: daysInMonth }, (_, index) => {
      const date = new Date(year, month, index + 1) // Adjusting month (0-based)
      const dayOfWeek = date.toLocaleDateString('it-IT', { weekday: 'short' }) // Get weekday abbreviation
      return `${dayOfWeek.toUpperCase()} ${index + 1}`
    })
    this.setState({ dayHeaders })

    const res = await Api.get('/hours', { month, year, baby, disableCache })
    if (res.ok) {
      this.setState({ hours: res.data })
    }
    this.setState({ loading: false })
  }

  async updateHours (_id, date, entrance, exit, meal, present) {
    const { baby } = this.state

    // patch hours
    const res = await Api.patch(`/hours/${_id}`, {
      date, entrance, exit, meal, present, baby
    })
    if (!res.ok) {
      toast('Errore durante l\'aggiornamento', { type: 'error', autoClose: 2000 })
    } else {
      toast('Dati di ingresso aggiornati', { type: 'success', autoClose: 2000 })
      const { year, month, baby } = this.state
      await this.fetchHoursMonthly(month, year, baby, true)
    }
  }

  async fetchStrutture () {
    const { user, impersonate } = this.global

    let owner = user._id
    if (impersonate) {
      owner = impersonate._id
    }

    const res = await Api.get('/baby', { owner })
    if (res.ok) {
      this.setState({ strutture: res.data })
      return res.data
    }
    return []
  }

  async creaNuovaStruttura () {
    const { user, company, impersonate } = this.global
    const { struttura: name } = this.state

    let owner = user._id
    if (impersonate) {
      owner = impersonate._id
    }

    const res = await Api.post('/baby', { name, owner, company })
    if (!res.ok) {
      toast('Errore durante la creazione della struttura', { type: 'error', autoClose: 2000 })
    } else {
      toast('Struttura creata correttamente', { type: 'success', autoClose: 2000 })
      await this.fetchStrutture()
      this.setState({ modalStrutture: false, struttura: '' })
    }
  }

  async downloadReport () {
    try {
      const { year, month } = this.state

      const res = await Api.get('/tools/report', { year, month })
      if (!res.ok) {
        toast('Errore durante il download del report.', { type: 'error', autoClose: 2000 })
        return
      }

      const link = document.createElement('a')
      link.href = res.data.url
      link.download = `Report_${year}-${months[month]}.csv`
      document.body.appendChild(link)
      link.click()

      link.parentNode.removeChild(link)

      setTimeout(async () => {
        await Api.get('/tools/report', { deleteReport: true, fileName: res.data.fileName })
      }, 10000)
    } catch (error) {
      console.error('Error downloading the file', error)
    }
  }

  render () {
    const { user } = this.global
    const {
      companyName, baby,
      itemSelected, strutture, hours, month, year, openCalendar, loading, dayHeaders,
      modalStrutture, struttura, showModalPagamento, pagamento, priceMeals
    } = this.state

    const convertTimeStringToMoment = (timeString) => {
      return moment(timeString, 'HH:mm')
    }

    const logout = () => {
      const { user } = this.global
      if (user.role === 'owner') {
        this.props.navigate('/')
      } else {
        this.setGlobal({ loggedIn: false }, persistState)
        clearState()
        window.location.reload()
      }
    }

    return (
      <div className='admview'>
        <Sidebar
          companyName={companyName}
          user={user.email}
          strutture={strutture}
          selected={itemSelected}
          selectBaby={async (baby) => {
            this.setState({ baby, itemSelected: baby })
            await this.fetchHoursMonthly(month, year, baby)
          }}
          showModalStrutture={() => this.setState({ itemSelected: 'strutture', modalStrutture: !modalStrutture })}
          logout={() => logout()}
        />

        <div className='admpage'>
          <div className='admheader'>
            <div className='admmonthselector'>
              <h5>Visualizza ingressi per il mese di</h5>
              <span
                className='admmonth'
                onClick={() => this.setState({ openCalendar: !openCalendar })}
              >
                {months[month]}
              </span>
              <Datetime
                className='monthpicker'
                dateFormat='MM'
                closeOnSelect
                open={openCalendar}
                onChange={async (date) => {
                  const month = date.month()
                  const year = date.year()
                  this.setState({ month, year, openCalendar: false })
                  await this.fetchHoursMonthly(month, year, this.state.baby)
                }}
              />
              {loading && <Loader />}
            </div>

            <div className='admtools'>
              <h5>Esporta i dati correnti</h5>
              {/* <AiOutlineFilePdf className='pdf' /> */}
              <AiOutlineFileExcel className='xls' onClick={async () => await this.downloadReport()} />
            </div>
          </div>

          <div className='admbody'>
            <div className='table-container'>
              <table className='fixed-table' style={{ display: (hours && hours.length > 0) ? 'block' : 'none' }}>
                <thead>
                  <tr>
                    <td className='fixed-column'>BAMBINO</td>
                    {dayHeaders && dayHeaders.map((day, j) => (
                      <td key={`head_${j}`} className='scrollable-columns title'>{day}</td>
                    ))}
                    <td className='fixed-last-column'>TOTALE</td>
                  </tr>
                </thead>
                <tbody>
                  {hours.map((row, k) => (
                    <tr key={`row_${k}`}>
                      <td className='fixed-column'>{row.childName}</td>
                      {
                        row && row.days && row.days.length > 0 && row.days.map((day, i) => {
                          return (
                            day
                              ? (
                                <td key={`col_${i}`} className='scrollable-columns'>
                                  <div className='hourconvalidated' style={{ display: day.convalidated ? 'flex' : 'none' }} />
                                  <div className='cellcontainer'>
                                    {day.present && <div className='tilemeal green' onClick={async () => await this.updateHours(day._id, day.date, day.entrance, day.exit, day.meal, false)}><MdCheckBox /><span>Presente</span></div>}
                                    {!day.present && <div className='tilemeal red' onClick={async () => await this.updateHours(day._id, day.date, day.entrance, day.exit, day.meal, true)}><MdCheckBoxOutlineBlank /><span>Assente</span></div>}

                                    <div className='hourdetail' style={{ display: day.present ? 'flex' : 'none' }}>
                                      <div className='hourclock'>
                                        <MobileTimePicker
                                          className='timepicker'
                                          openTo='hours'
                                          ampm={false}
                                          value={convertTimeStringToMoment(day.entrance)}
                                          onChange={async e => await this.updateHours(day._id, day.date, moment(e).format('HH:mm'), day.exit, day.meal, day.present)}
                                        />
                                      </div>
                                      <div className='hourclock' style={{ marginLeft: 10 }}>
                                        <MobileTimePicker
                                          className='timepicker'
                                          openTo='hours'
                                          ampm={false}
                                          value={convertTimeStringToMoment(day.exit)}
                                          onChange={async e => await this.updateHours(day._id, day.date, day.exit, moment(e).format('HH:mm'), day.meal, day.present)}
                                        />
                                      </div>
                                    </div>

                                    {day.meal && day.present && <div className='tilemeal' onClick={async () => await this.updateHours(day._id, day.date, day.entrance, day.exit, false, day.present)}><MdCheckBox /><span>Pasto</span></div>}
                                    {!day.meal && day.present && <div className='tilemeal' onClick={async () => await this.updateHours(day._id, day.date, day.entrance, day.exit, true, day.present)}><MdCheckBoxOutlineBlank /><span>Pasto</span></div>}
                                  </div>
                                </td>
                                )
                              : <td key={`col_${i}`} className='scrollable-columns'>Nessun ingresso</td>
                          )
                        })
                      }
                      <td className='fixed-last-column'>
                        <div className='orepasti'>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>Ore: <b>{row.totalHours}:{row.totalMinutes}</b></span>
                            {row.payment && <span className='labelgreen'>({row.payment ? (row.payment.priceSconto ? (row.payment.hours - row.payment.priceSconto) : row.payment.hours) : ''}€)</span>}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>Pasti: <b>{row.meals}</b></span>
                            {row.payment && <span className='labelgreen'>({row.payment ? row.payment.meal : ''}€)</span>}
                          </div>
                          <div
                            className='btnrilascia'
                            style={{ backgroundColor: !row.payment ? '#DA7297' : row.payment.status === 'pagamento' ? '#DA7297' : (row.payment.status === 'inviato' ? '#ebb734' : (row.payment.status === 'fatturato' ? '#ff6600' : (row.payment.status === 'incassato' ? '#16a622' : '#DA7297'))) }}
                            onClick={async () => {
                              if (row.payment.status === 'incassato') return

                              if (!row.payment || row.payment === 'pagamento') {
                                const hoursDecimal = Number(row.totalHours) + Number((Number(row.totalMinutes) / 60).toFixed(2))
                                this.setState({
                                  showModalPagamento: true,
                                  pagamento: {
                                    hours: `${row.totalHours}:${row.totalMinutes}`,
                                    hoursDecimal,
                                    meals: row.meals,
                                    user: row.user,
                                    userEmail: row.userEmail,
                                    child: row.child
                                  },
                                  priceMeals: row.meals * MEAL_PRICE
                                })
                              } else {
                                let status = ''
                                if (row.payment.status === 'inviato') {
                                  status = 'fatturato'
                                }
                                if (row.payment.status === 'fatturato') {
                                  status = 'incassato'
                                }

                                const res = await Api.patch(`/stats/${row.payment.id}`, { status })
                                if (!res.ok) {
                                  toast('Errore durante l\'aggiornamento del pagamento.', { type: 'error', autoClose: 2000 })
                                } else {
                                  toast('Pagamento aggiornato con successo.', {
                                    type: 'success',
                                    autoClose: 1500,
                                    onClose: async () => {
                                      const { baby } = this.state
                                      await this.fetchHoursMonthly(month, year, baby, true)
                                    }
                                  })
                                }
                              }
                            }}
                          >
                            {!row.payment && <span>Pagamento</span>}
                            {row.payment && <span>{row.payment.status === 'pagamento' ? 'Pagamento' : (row.payment.status === 'inviato' ? 'Inviato' : (row.payment.status === 'fatturato' ? 'Fatturato' : 'Incassato'))}</span>}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {
                (!hours || hours.length === 0) &&
                  <div style={{ padding: 20, fontWeight: '300', fontSize: 20 }}>
                    <span>Non sono presenti ingressi per il mese selezionato</span>
                  </div>
              }
            </div>
          </div>
        </div>

        <Modal
          isOpen={modalStrutture}
          style={customStyle}
          className='netmodal'
          ariaHideApp={false}
          onRequestClose={() => this.setState({ modalStrutture: !modalStrutture })}
        >
          <div className='modalstrut'>
            <h5>Crea una nuova struttura baby parking</h5>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <p>Nome Struttura</p>
              <input
                type='text'
                value={struttura}
                style={{ height: 30, width: 200, marginLeft: 10, marginRight: 10 }}
                onChange={e => this.setState({ struttura: e.target.value })}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className='btnrilascia' style={{ width: 150 }} onClick={async () => await this.creaNuovaStruttura()}>
                <span>Salva</span>
              </div>
            </div>
          </div>
        </Modal>

        {
          pagamento &&
            <Pagamento
              showModalPagamento={showModalPagamento}
              closeModal={() => this.setState({ showModalPagamento: false, pagamento: null })}
              year={year}
              month={month}
              baby={baby}
              pagamento={pagamento}
              priceMeals={priceMeals}
              fetchHoursMonthly={async (month, year, baby, disableCache) => await this.fetchHoursMonthly(month, year, baby, disableCache)}
            />
        }
      </div>
    )
  }
}

const customStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    position: 'absolute',
    zIndex: 999,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20
  },
  outline: 'none'
}

export default WithRouter(Admin)
