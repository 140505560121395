import React, { Component } from 'react'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import { Api } from '../../Services'

const nMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

const DEFAULT_MODAL_PAGAMENTO = {
  pagamento: {
    hours: '0',
    meals: 0,
    user: '',
    userEmail: '',
    child: '',
    hoursDecimal: 0
  },
  priceHours: 0,
  priceBonus: 0,
  pricePercSconto: 0,
  priceSconto: 0,
  priceMeals: 0,
  priceHourly: 0
}

class Pagamento extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...DEFAULT_MODAL_PAGAMENTO
    }
  }

  componentDidMount () {
    const { pagamento, priceMeals } = this.props
    this.setState({ pagamento, priceMeals })
    this.calculatePriceHourly(pagamento.hoursDecimal)
  }

  calculatePriceHourly (totalHours) {
    let priceHourly

    if (totalHours <= 39) {
      priceHourly = 6.30
    } else if (totalHours >= 40 && totalHours <= 59) {
      priceHourly = 5.80
    } else if (totalHours >= 60 && totalHours <= 79) {
      priceHourly = 5.25
    } else if (totalHours >= 80 && totalHours <= 99) {
      priceHourly = 4.80
    } else if (totalHours >= 100 && totalHours <= 109) {
      priceHourly = 4.20
    } else if (totalHours >= 110 && totalHours <= 130) {
      priceHourly = 3.70
    } else if (totalHours >= 131 && totalHours <= 150) {
      priceHourly = 3.50
    } else if (totalHours >= 151 && totalHours <= 179) {
      priceHourly = 3.30
    } else if (totalHours >= 180) {
      priceHourly = 3.00
    } else {
      priceHourly = 0
    }

    this.setState({ priceHourly })
  }

  async salvaPagamento (user, email, child, hours, meals) {
    const { year, month, baby } = this.props
    const { priceHourly, priceHours, priceBonus, priceMeals, priceSconto } = this.state
    const date = `${year}-${nMonths[month]}-01`

    const res = await Api.post('/stats', {
      date,
      baby: [{
        priceHour: priceHourly,
        hours,
        total: priceHours
      }],
      meal: {
        count: meals,
        total: priceMeals
      },
      bonus: priceBonus,
      priceSconto,
      user,
      child,
      status: 'inviato'
    })
    if (!res.ok) {
      toast('Errore durante il salvataggio del pagamento.', { type: 'error', autoClose: 2000 })
    } else {
      toast('Pagamento salvato correttamente.', {
        type: 'success',
        autoClose: 1500,
        onClose: async () => {
          this.setState({ modalStrutture: false, struttura: '' })
          this.props.closeModal()
          await this.props.fetchHoursMonthly(month, year, baby, true)

          const ret = await Api.post('/emails', {
            from: 'hello@winniearcobaleno.it',
            to: email,
            subject: 'Pagamento mensile Baby Parking',
            payment: true
          })

          if (ret.ok) {
            toast('E-mail di avviso pagamento inviata correttamente.', {
              type: 'success',
              autoClose: 1500
            })
          }
        }
      })
    }

    this.setState({
      modalPagamento: false,
      priceHours: 0,
      priceBonus: 0,
      priceMeals: 0,
      priceHourly: 0,
      pagamento: {
        hours: '',
        meals: 0,
        user: '',
        child: ''
      }
    })
  }

  render () {
    const { showModalPagamento } = this.props
    const {
      pagamento, priceHours, priceBonus,
      pricePercSconto, priceSconto, priceMeals, priceHourly
    } = this.state

    return (
      <Modal
        isOpen={showModalPagamento}
        style={customStyle}
        className='netmodal'
        ariaHideApp={false}
        onRequestClose={() => {
          this.setState({ ...DEFAULT_MODAL_PAGAMENTO })
          this.props.closeModal()
        }}
      >
        <h3 style={{ marginBottom: 30, marginTop: 0 }}>Dettaglio pagamento mensile per il genitore</h3>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div style={{ width: 200, display: 'flex', justifyContent: 'flex-end' }}>
            <span className='pagtitle'>Ore totali:</span>
            <span className='paglabel'>{pagamento.hours}</span>
          </div>
          <br />
          <div style={{ width: 200, display: 'flex', justifyContent: 'flex-end' }}>
            <span className='pagtitle'>Pasti totali:</span>
            <span className='paglabel'>{pagamento.meals}</span>
          </div>
          <br />
          <hr style={{ width: '100%' }} />
          <br />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 40 }}>
              <span className='pagtitle'>Prezzo Orario:</span>
              <input
                type='number'
                value={priceHourly}
                style={{ height: 30, width: 120, marginLeft: 10, marginRight: 10 }}
                onChange={e => this.setState({ priceHourly: e.target.value })}
              />
              <br />
              <span className='pagtitle'>
                Prezzo mensile: ({(pagamento.hours && pagamento.hours !== '0') ? Number((priceHourly * pagamento.hoursDecimal).toFixed(2)) : 0} €)
              </span>
              <input
                type='number'
                value={priceHours}
                style={{ height: 30, width: 120, marginLeft: 10, marginRight: 10 }}
                onChange={e => this.setState({ priceHours: e.target.value })}
              />
            </div>
            <br />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='pagtitle'>Bonus:</span>
              <input
                type='number'
                value={priceBonus}
                style={{ height: 30, width: 120, marginLeft: 10, marginRight: 10 }}
                onChange={e => this.setState({ priceBonus: e.target.value })}
              />
              <br />
              <span className='pagtitle'>Pasti:</span>
              <input
                type='number'
                value={priceMeals}
                style={{ height: 30, width: 120, marginLeft: 10, marginRight: 10 }}
                onChange={e => this.setState({ priceMeals: e.target.value })}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 50 }}>
              <span className='pagtitle'>Sconto (default 20%):</span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                  type='number'
                  value={pricePercSconto}
                  style={{ height: 30, width: 50, marginLeft: 10, marginRight: 10 }}
                  onChange={e => {
                    const pricePercSconto = Number(e.target.value)
                    let priceSconto = 0
                    if (pricePercSconto > 0) {
                      priceSconto = priceHours * pricePercSconto / 100
                    }
                    this.setState({ pricePercSconto, priceSconto })
                  }}
                />
                <input
                  type='number'
                  value={priceSconto}
                  style={{ height: 30, width: 100, marginLeft: 10, marginRight: 10 }}
                  onChange={e => this.setState({ priceSconto: e.target.value })}
                />
              </div>
              <br />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10, marginTop: 20 }}>
          <div className='btnrilascia' style={{ width: 150 }} onClick={async () => await this.salvaPagamento(pagamento.user, pagamento.userEmail, pagamento.child, pagamento.hours, pagamento.meals)}>
            <span>Invia</span>
          </div>
        </div>
      </Modal>
    )
  }
}

const customStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    position: 'absolute',
    zIndex: 999,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20
  },
  outline: 'none'
}

export default Pagamento
