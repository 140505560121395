import React, { Component } from 'reactn'
import { MdAlarm, MdCalendarMonth, MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import moment from 'moment'
import { DatePicker, WithRouter } from '../../../Components'
import './Entrances.css'
import { Api, clearState, persistState } from '../../../Services'
import { MobileTimePicker } from '@mui/x-date-pickers'
import { toast } from 'react-toastify'

class Entrances extends Component {
  constructor (props) {
    super(props)
    this.state = {
      today: new Date(),
      entrances: [],
      babyName: '',
      periodCount: null
    }
  }

  async fetchEntrances (today) {
    const { user } = this.global

    const res = await Api.get('/hours', { from: today, to: today, baby: user.baby[0] })
    if (res.ok) {
      const periodOrder = { mattino: 0, pomeriggio: 1, intero: 2, assente: 3 }
      const entrances = res.data.sort((a, b) => periodOrder[a.period] - periodOrder[b.period])
      this.setState({ entrances })

      const periodCount = {
        mattino: 0,
        pomeriggio: 0,
        intero: 0,
        assente: 0
      }

      entrances.forEach(entry => {
        if (['mattino', 'pomeriggio', 'intero', 'assente'].includes(entry.period)) {
          periodCount[entry.period]++
        }
      })

      console.log(periodCount)
      this.setState({ periodCount })
    }

    const ret = await Api.get(`/baby/${user.baby[0]}`)
    if (ret.ok) {
      this.setState({ babyName: ret.data.name })
    }
  }

  async updateHours (user, date, entrance, exit, meal, present, child, convalidatedMorning, convalidated) {
    const res = await Api.post('/hours', {
      user,
      date,
      entrance,
      exit,
      meal,
      present,
      child,
      convalidatedMorning,
      convalidated
    })

    if (!res || !res.ok) {
      toast('Si è verificato un errore nel salvataggio dell\'ingresso. Si prega di riprovare più tardi.', { type: 'error', autoClose: 2000 })
    } else {
      toast('Salvato', { type: 'success', autoClose: 1500 })
      const { today } = this.state
      await this.fetchEntrances(today)
    }
  }

  logout = () => {
    this.setGlobal({ loggedIn: false }, persistState)
    clearState()
    window.location.reload()
  }

  render () {
    const { entrances, babyName, periodCount } = this.state

    const getWeekday = (dateString) => {
      const date = new Date(dateString)
      const weekdays = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
      return weekdays[date.getDay()]
    }

    const convertTimeStringToMoment = (timeString) => {
      return moment(timeString, 'HH:mm')
    }

    return (
      <div className='entra'>
        <div className='entrapage'>
          <div className='entraheader'>
            <div className='entratop'>
              <h3>Ingressi giornalieri</h3>
              <h4 style={{ marginTop: -10 }}>di Baby {babyName}</h4>
              <span className='childlogout' onClick={() => this.logout()}>Logout</span>
            </div>
            <div className='entraweek'>
              <span>Qui é possibile modificare gli orari, segnare il bambino assente o variare il pasto.
                Quando tutti i dati sono corretti si può procedere al salvataggio premendo su Convalida
              </span>
              <div className='entraweekselector'>
                <DatePicker
                  onChange={date => {
                    const today = moment(date).format('YYYY-MM-DD')
                    this.setState({ today })
                    this.fetchEntrances(today)
                  }}
                />
                <MdCalendarMonth />
              </div>
            </div>
          </div>

          <div className='entrabody'>
            {
              periodCount &&
                <div className='period'>
                  <span className='presenze'>Presenze del giorno</span>
                  <div className='periodCol'>
                    <div>
                      <span className='periodTitle'>Mattino:</span>
                      <span className='periodNumber'>{periodCount.mattino}</span>
                    </div>
                    <div>
                      <span className='periodTitle'>Pomeriggio:</span>
                      <span className='periodNumber'>{periodCount.pomeriggio}</span>
                    </div>
                  </div>
                  <div className='periodCol'>
                    <div>
                      <span className='periodTitle'>Intero:</span>
                      <span className='periodNumber'>{periodCount.intero}</span>
                    </div>
                    <div>
                      <span className='periodTitle'>Assenti:</span>
                      <span className='periodNumber'>{periodCount.assente}</span>
                    </div>
                  </div>
                </div>
            }
            {
              entrances && entrances.length > 0 && entrances.map((item, i) => {
                const { user, date, entrance, exit, meal, present, child, childName, period, convalidatedMorning, convalidated } = item

                return (
                  <div key={i} className='entratimeline'>
                    <div className='entracard'>
                      <div
                        className='entracardname'
                        style={{ backgroundColor: period === 'intero' ? '#508D4E' : (period === 'mattino' ? '#03346E' : (period === 'pomeriggio' ? '#FFD35A' : '#AE0808')) }}
                      >
                        <span>{childName}</span>
                      </div>

                      <div className='entracardheader'>
                        <span className='entratitle'>{getWeekday(date)} {moment(date).format('DD/MM/YYYY').substring(0, 5)}</span>
                        {present && <div className='tilemeal green' onClick={async () => !convalidated && await this.updateHours(user, date, entrance, exit, meal, false, child, convalidatedMorning, false)}><MdCheckBox /><span>Presente</span></div>}
                        {!present && <div className='tilemeal red' onClick={async () => !convalidated && await this.updateHours(user, date, entrance, exit, meal, true, child, convalidatedMorning, false)}><MdCheckBoxOutlineBlank /><span>Assente</span></div>}
                        {meal && present && <div className='tilemeal' onClick={async () => !convalidated && await this.updateHours(user, date, entrance, exit, !meal, present, child, convalidatedMorning, false)}><MdCheckBox /><span>Pasto</span></div>}
                        {!meal && present && <div className='tilemeal' onClick={async () => !convalidated && await this.updateHours(user, date, entrance, exit, !meal, present, child, convalidatedMorning, false)}><MdCheckBoxOutlineBlank /><span>Pasto</span></div>}
                      </div>
                      <div className='entradetail' style={{ display: present ? 'flex' : 'none' }}>
                        <div className='entratime'>
                          <span>Ingresso</span>
                          <div className='entraclock'>
                            <MdAlarm />
                            <MobileTimePicker
                              className='timepicker'
                              openTo='entras'
                              ampm={false}
                              value={convertTimeStringToMoment(entrance)}
                              disabled={convalidatedMorning}
                              onChange={async e => await this.updateHours(user, date, moment(e).format('HH:mm'), exit, meal, present, child, convalidatedMorning, false)}
                            />
                          </div>
                        </div>
                        <div className='entratime'>
                          <span>Uscita</span>
                          <div className='entraclock'>
                            <MdAlarm />
                            <MobileTimePicker
                              className='timepicker'
                              openTo='entras'
                              ampm={false}
                              disabled={convalidated}
                              value={convertTimeStringToMoment(exit)}
                              onChange={async e => await this.updateHours(user, date, entrance, moment(e).format('HH:mm'), meal, present, child, convalidatedMorning, false)}
                            />
                          </div>
                        </div>
                      </div>
                      {
                        !convalidated &&
                          <div className='entratools'>
                            <div
                              className='entrabutton convalida'
                              style={{ width: 180 }}
                              onClick={async () => await this.updateHours(user, date, entrance, exit, meal, present, child, true, convalidatedMorning)}
                            >
                              {present && <span>Convalida {!convalidatedMorning ? 'Ingr.' : 'Usc.'}</span>}
                              {!present && <span>Convalida Assenza</span>}
                            </div>
                          </div>
                      }
                      {
                        convalidated &&
                          <div className='conva'>
                            <div className='conva green'><span>Convalidato</span><MdCheckBox /></div>
                          </div>
                      }
                    </div>
                  </div>
                )
              })
            }
            {
              (!entrances || entrances.length === 0) &&
                <div>
                  <span>Non sono presenti ingressi per la data selezionata</span>
                </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default WithRouter(Entrances)
